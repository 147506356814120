<script setup>
import ContentTabs from '@app/components/layout/ContentTabs.vue'
import AppointmentDetails from '@app/components/recruitment/appointments/AppointmentDetails.vue'
import AddNote from '@app/components/recruitment/leads/actions/AddNote.vue'
import CallLead from '@app/components/recruitment/leads/actions/CallLead.vue'
import EditLead from '@app/components/recruitment/leads/actions/EditLead.vue'
import EditLeadClientInformation from '@app/components/recruitment/leads/actions/EditLeadClientInformation.vue'
import LinkToMfsLead from '@app/components/recruitment/leads/actions/LinkToMfsLead.vue'
import ManageRecruitmentAppointment from '@app/components/recruitment/leads/actions/ManageRecruitmentAppointment.vue'
import RestoreLead from '@app/components/recruitment/leads/actions/RestoreLead.vue'
import SelectLeadOutcome from '@app/components/recruitment/leads/actions/SelectLeadOutcome.vue'
import SendEmail from '@app/components/recruitment/leads/actions/SendEmail.vue'
import SendWhatsappMessage from '@app/components/recruitment/leads/actions/SendWhatsappMessage.vue'
import LeadCard from '@app/components/recruitment/leads/LeadCard.vue'
import LeadClientCard from '@app/components/recruitment/leads/LeadClientCard.vue'
import LeadStatusFilter from '@app/components/recruitment/leads/LeadStatusFilter.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import DropdownDateFilter from '@app/components/ui/table/filters/DropdownDateFilter.vue'
import DropdownFutureDateFilter from '@app/components/ui/table/filters/DropdownFutureDateFilter.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import LeadStatusTag from '@app/components/ui/tag/LeadStatusTag.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useDetailCard } from '@app/composables/useDetailCard'
import { useChannel } from '@app/composables/useEcho'
import { useUserCan } from '@app/composables/useUserCan'
import { reloadPartial } from '@app/utils/inertia'
import { getLeadsLink } from '@app/utils/recruitment/lead'
import { trans } from 'laravel-vue-i18n'
import { computed, toRefs } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    blocks: Object,
    funnelBranches: Array,
    callAttemptOptions: Array,
    channels: Array,
    funnels: Object,
    leads: {
        type: Object,
        required: true,
    },
    funnel: Object,
    funnelBlock: Object,
    selectedLead: Object,
    channelCategories: Array,
    recruitmentSelectionClients: Array,
    clientProjects: Array,
})

const { selectedLead } = toRefs(props)

const { toggleCard } = useDetailCard(selectedLead, 'selectedLead')
const { subscribe } = useChannel('recruitment')

const { getFunnelBlockTabs } = useNavigation()
const { isInternalUser } = useUserCan()
const params = route().params

const tabs = getFunnelBlockTabs(props.funnel)
const isRecruitmentSelectionFunnel = computed(() => props.funnel.slug === 'recruitment-selection')
const hasCallbackAppointments = computed(() => props.funnelBlock.statuses.some(({ slug }) => slug === 'callback-appointment'))

const isDeactivatedUserAnswers = [
    { id: 1, name: trans('form.labels.yes') },
    { id: 0, name: trans('form.labels.no') },
]

const columns = computed(() => [
    {
        name: 'name',
        header: 'recruitment.leads.index.name',
        sort: 'first_name',
    },
    {
        name: 'status',
        header: 'recruitment.leads.index.status',
        show: !selectedLead.value || isRecruitmentSelectionFunnel.value,
    },
    {
        name: 'appointment',
        header: 'recruitment.call_appointment.date',
        show: props.funnelBlock.has_appointments || hasCallbackAppointments.value,
        sort: hasCallbackAppointments.value ? 'callback_appointment' : 'recruitment_appointment',
    },
    {
        name: 'client',
        header: 'attributes.client',
        show: isInternalUser.value && isRecruitmentSelectionFunnel.value && ['interested', 'external'].includes(props.funnelBlock.slug),
    },
    {
        name: 'project',
        header: 'attributes.client_project',
        show: props.funnelBlock.slug === 'workshop',
    },
    {
        name: 'job_preference',
        header: 'attributes.job_preference',
        show: isRecruitmentSelectionFunnel.value && ['interested', 'external'].includes(props.funnelBlock.slug),
    },
    {
        name: 'is_deactivated_user',
        header: 'attributes.is_deactivated_user',
        show: isRecruitmentSelectionFunnel.value && ['lead', 'interested'].includes(props.funnelBlock.slug),
    },
    {
        type: 'multi-line-date',
        header: 'recruitment.leads.index.entered_funnel_at',
        properties: { name: 'entered_funnel_at' },
        sort: 'entered_funnel_at',
        show: props.funnelBlock.slug === 'lead',
    },
    {
        type: 'string',
        header: 'recruitment.leads.index.phone_number',
        show: props.funnelBlock.slots === 'external',
        properties: { name: 'phone_number' },
        color: 'primary',
    },
    {
        type: 'multi-line-date',
        header: 'attributes.last_contact_at',
        properties: { name: 'last_contact_at' },
        sort: 'last_contact_at',
        show: ['lead', 'interested'].includes(props.funnelBlock.slug),
    },
    {
        name: 'recruiter',
        header: 'attributes.recruited_by',
        sort: 'recruited_by',
        show: props.funnelBlock.has_appointments,
    },
    {
        type: 'multi-line',
        header: 'recruitment.leads.index.channel',
        properties: {
            name: 'channel_category',
            primaryName: 'channel_type_category',
            secondaryName: 'channel_name',
        },
        sort: 'channel',
        show: props.funnelBlock.slug !== 'external',
    },
])

subscribe('.recruitment.lead.created', () => reloadPartial(['leads']))
</script>

<template>
    <ContentTabs bottomBorder :items="tabs" />
    <div class="flex items-start gap-4 scrollbar-hide">
        <DataTable
            class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :class="[!selectedLead ? 'inline-block' : 'hidden lg:inline-block']"
            :items="leads"
            :columns="columns"
            @row:clicked="(lead) => toggleCard(lead.id)"
            :activeId="selectedLead ? selectedLead.id : null"
            only="leads"
            :clickable="true"
        >
            <template #filter="{ hasColumn }">
                <FilterSection :enable-search="true" :text-search-param="params.name">
                    <LeadStatusFilter :statuses="funnelBlock.statuses" :callAttemptOptions="callAttemptOptions" />

                    <DropdownMultiFilter :items="funnelBranches" :label="$t('recruitment.leads.index.branch')" slug="branch" by="slug" />

                    <DropdownMultiFilter
                        v-if="isRecruitmentSelectionFunnel && ['lead', 'interested'].includes(props.funnelBlock.slug)"
                        :items="isDeactivatedUserAnswers"
                        :label="$t('attributes.is_deactivated_user')"
                        slug="is_deactivated_user"
                        by="id"
                    />

                    <DropdownMultiFilter
                        v-if="!isRecruitmentSelectionFunnel"
                        :items="channelCategories"
                        :label="$t('attributes.category')"
                        slug="channel_category"
                    />

                    <DropdownMultiFilter v-if="hasColumn('client')" :items="recruitmentSelectionClients" :label="$t('attributes.client')" slug="client" />

                    <DropdownMultiFilter v-if="hasColumn('project')" :items="clientProjects" :label="$t('attributes.client_project')" slug="client_project" />

                    <DropdownDateFilter
                        v-if="hasColumn('entered_funnel_at')"
                        :label="$t('recruitment.leads.index.entered_funnel_at')"
                        slug="entered_funnel_at"
                    />

                    <DropdownDateFilter v-if="hasColumn('last_contact_at')" :label="$t('attributes.last_contact_at')" slug="last_contact_at" />

                    <DropdownFutureDateFilter
                        v-if="funnelBlock.has_appointments"
                        :label="$t('recruitment.ongoing_appointment')"
                        slug="recruitment_appointment"
                    />

                    <DropdownFutureDateFilter v-else-if="hasCallbackAppointments" :label="$t('recruitment.ongoing_appointment')" slug="callback_appointment" />

                    <ResetFilterButton :href="getLeadsLink()" :label="$t('buttons.clear')" />
                </FilterSection>
            </template>

            <template v-slot:column.name="{ item }">
                <div class="text-gray-900">
                    {{ item.full_name }}
                </div>
                <div v-if="!isRecruitmentSelectionFunnel" class="text-gray-500">
                    {{ item.branch.name }}
                </div>

                <div v-else-if="item.client_information?.region" class="max-w-40 truncate text-gray-500">
                    {{ item.client_information.region }}
                </div>
            </template>

            <template v-slot:column.status="{ item }">
                <LeadStatusTag :status="item.funnel_block_status.slug">{{ item.status_incl_call_attempts }}</LeadStatusTag>
            </template>

            <template v-slot:column.appointment="{ item }">
                <div>
                    <AppointmentDetails
                        v-if="item.ongoing_call_appointment"
                        class="mt-1"
                        :from="item.ongoing_call_appointment.from"
                        :to="item.ongoing_call_appointment.to"
                    />

                    <AppointmentDetails
                        v-if="item.ongoing_recruitment_appointment"
                        class="mt-1"
                        :from="item.ongoing_recruitment_appointment.recruitment_availability.from"
                        :to="item.ongoing_recruitment_appointment.recruitment_availability.to"
                    />
                </div>
            </template>

            <template v-slot:column.client="{ item }">
                <div class="text-gray-900">
                    {{ item.client_information?.client.name }}
                </div>
            </template>

            <template v-slot:column.project="{ item }">
                <div class="text-gray-900">
                    {{ item.ongoing_recruitment_appointment?.recruitment_availability?.client_project?.name }}
                </div>
            </template>

            <template v-slot:column.job_preference="{ item }">
                <div class="max-w-64 whitespace-normal text-gray-900">
                    {{ item.client_information?.job_preference }}
                </div>
            </template>

            <template v-slot:column.recruiter="{ item }">
                <div class="text-gray-900">
                    {{ item.recruited_by }}
                </div>
            </template>
            <template v-slot:column.is_deactivated_user="{ item }">
                <div class="pr-6' whitespace-nowrap pl-6 text-sm">
                    {{ item.is_deactivated_user ? trans('form.labels.yes') : trans('form.labels.no') }}
                </div>
            </template>
        </DataTable>

        <Transition name="slide-fade" mode="out-in">
            <template v-if="selectedLead">
                <LeadClientCard
                    v-if="selectedLead.funnel_block.slug === 'external'"
                    :lead="selectedLead"
                    class="md:min-w-[29rem] lg:w-2/3"
                    @close="toggleCard(selectedLead.id)"
                    @reload="reloadPartial(['selectedLead', 'leads'])"
                />

                <LeadCard
                    v-else
                    :lead="selectedLead"
                    class="md:min-w-[29rem] lg:w-2/3"
                    :dismissable="true"
                    @close="toggleCard(selectedLead.id)"
                    @reload="reloadPartial(['selectedLead', 'leads'])"
                >
                    <CallLead :lead="selectedLead" />
                    <SendWhatsappMessage :lead="selectedLead" />
                    <SendEmail :lead="selectedLead" id="email_button" />
                    <AddNote :lead="selectedLead" id="note_button" />
                    <EditLeadClientInformation :lead="selectedLead" />
                    <EditLead :lead="selectedLead" id="edit_button" />
                    <ManageRecruitmentAppointment :lead="selectedLead" />
                    <RestoreLead :lead="selectedLead" />
                    <SelectLeadOutcome :lead="selectedLead" />
                    <LinkToMfsLead :lead="selectedLead" />
                </LeadCard>
            </template>
        </Transition>
    </div>
</template>
